<template>
  <drawer-root
    v-model:open="isVisible"
    :dismissible="closable"
  >
    <drawer-portal>
      <drawer-overlay
        v-if="withBackdrop"
        :style="{zIndex: zIndex}"
        class="fixed bg-black/40 inset-0"
      />
      <drawer-content
        v-if="isVisible || !lazy"
        v-show="lazy || isVisible"
        :style="{zIndex: zIndex + 1}"
        :class="[{'h-[100vh]': fullHeight}, drawerClass]"
        class="bg-white p-4 flex flex-col rounded-t-[10px]  mt-24 max-h-[96%] fixed bottom-0 left-0 right-0"
      >
        <div
          class="mt-[-33px] mb-[33px] pt-3"
        >
          <div class="bg-gray-300 h-[5px] w-[60px] rounded-full mx-auto cursor-pointer" />
        </div>
        <div
          class="pb-0 flex flex-col bg-white rounded-t-md h-full overflow-y-auto overscroll-y-contain"
          :class="bodyClass"
        >

          <h3
            v-if="!hideHeader"
            class="text-xl dark:text-white pb-5"
            :class="headerClass"
          >
            <slot name="header" />
            {{ title }}
          </h3>
          <div
            class="grow"
            :class="contentClass"
          >
            <slot />
            <slot name="body" />
          </div>
          <div
            v-if="!hideFooter"
            class="mt-2 flex gap-x-4"
            :class="footerClass"
          >
            <slot name="footer" />
          </div>
        </div>
      </drawer-content>
    </drawer-portal>
  </drawer-root>
</template>
<script setup lang="ts">
import baseZIndex from '~/ui/drawers/constants/Drawers.zIndex'
import { useAppStateStore } from '~/store/app'
import { DrawerContent, DrawerOverlay, DrawerPortal, DrawerRoot } from 'vaul-vue'

const props = defineProps({
  modelValue: {
    type: Boolean,
    default: false,
  },
  title: {
    type: String,
    default: null,
  },
  withBackdrop: {
    type: Boolean,
    default: true,
  },
  closable: {
    type: Boolean,
    default: true,
  },
  hideHeader: {
    type: Boolean,
    default: true,
  },
  fullHeight: {
    type: Boolean,
    default: false,
  },
  bodyClass: {
    type: [String, Object, Array],
    default: '',
  },
  drawerClass: {
    type: [String, Object, Array],
    default: '',
  },
  headerClass: {
    type: [String, Object, Array],
    default: '',
  },
  contentClass: {
    type: [String, Object, Array],
    default: null,
  },
  footerClass: {
    type: [String, Object, Array],
    default: null,
  },
  hideFooter: {
    type: Boolean,
    default: false,
  },
  lazy: {
    type: Boolean,
    default: true,
  },
})

const emits = defineEmits<{(e: 'update:modelValue', value: boolean): void}>()

const store = useAppStateStore()

const isVisible = customRef(() => ({
  get() {
    return props.modelValue
  },
  set(newValue) {
    emits('update:modelValue', newValue)
  },
}))

const zIndex = ref(baseZIndex)
const updateZIndex = () => {
  zIndex.value = baseZIndex + store.openElementsCount.modals * 2
}

watch(isVisible, newValue => {
  if (newValue) {
    store.openModal()
    updateZIndex()
  } else {
    store.closeModal()
  }
})

onMounted(() => {
  if (isVisible.value) {
    store.openModal()
    updateZIndex()
  }
})
onBeforeUnmount(() => {
  if (isVisible.value) {
    store.closeModal()
  }
})

</script>
