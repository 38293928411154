export const getDateAndTime = (dateString: string) => {
  const date = new Date(dateString)

  const day = date.getDate().toString().padStart(2, '0')
  const month = (date.getMonth() + 1).toString().padStart(2, '0')
  const year = date.getFullYear().toString().slice(-2)
  const hours = date.getHours()
  const minutes = date.getMinutes()

  return `${day}.${month}.${year} ${hours}:${minutes}`
}
