<template>
  <div class="flex justify-between">
    <h2 class="font-semibold text-neutral-900 text-3xl mb-5">
      {{ $t('building.flatsInfo.flats.label.full') }}
    </h2>
    <div
      v-if="flatDisplayType === DISPLAY_TYPE.basic && building.lastImportRunDate "
      class="mt-2 flex gap-x-1"
    >
      <div>
        {{ $t('building.lastImportRunDate') }}:
      </div>
      <div class="font-semibold">
        {{ getDateAndTime(building.lastImportRunDate) }}
      </div>
    </div>
  </div>
  <flats-filter v-model:filter="store.filter">
    <template #resetButton>
      <reset-button
        variant="full"
        @reset-filter="store.resetFilter"
      />
    </template>
    <template #searchButton>
      <search-button
        :is-fetching="isFetching"
        :disabled="isFetching"
        @click="getFlatsInfo"
      >
        {{ $t('entityActions.show.flats', searchCount) }}
      </search-button>
    </template>
    <template #resetFlatsFilterButton>
      <reset-button
        @reset-filter="store.resetFilter"
      />
    </template>
    <template #searchModalButton="{close}">
      <search-button
        class="w-1/3"
        :is-fetching="isFetching"
        :disabled="isFetching"
        @click="() => {getFlatsInfo(); close()}"
      >
        {{ $t('entityActions.show.flats', searchCount) }}
      </search-button>
    </template>

  </flats-filter>
  <div class="flex gap-x-2 md:gap-x-4 justify-between mt-5 pt-5 border-t border-neutral-35">
    <div class="flex gap-x-2 md:gap-x-4">
      <flats-select-displaying-mode
        :building="building"
        :flat-display-type="flatDisplayType"
      />
    </div>
    <flats-sort
      v-model:sort-option="flatsSortOption"
    />
  </div>
  <div
    v-show="isFetching"
    class="flex justify-center items-center pt-4"
  >
    <custom-cube-spinner />
  </div>
  <div
    v-show="!isFetching"
  >
    <slot name="managerChosenFlats" />
    <flats-house-list
      :houses="houses"
      :house-flats-info-by-id="houseFlatsInfoById"
      :display-type="flatDisplayType"
      :building="building"
    >
      <template #showAvailable>
        <custom-button
          class="font-semibold"
          pill
          @click="toggleFilterStatus"
        >
          <div class="flex gap-2">
            <span v-if="isNotAvailableShown">
              {{ $t('flat.button.showAvailableFlats.hide.label') }}
            </span>
            <span v-else>
              {{ $t('flat.button.showAvailableFlats.show.label') }}
            </span>
            <client-only>
              <font-awesome-icon
                :icon="['fas', isNotAvailableShown ? 'eye' : 'eye-slash']"
                class="ml-2"
              />
            </client-only>
          </div>
        </custom-button>
      </template>
    </flats-house-list>
  </div>
</template>

<script setup lang="ts">
import FlatsHouseList from '~/modules/building/components/page/flats/house/FlatsHouseList.vue'
import SearchButton from '~/modules/market-entities-actions/components/filter/SearchButton.vue'
import FlatsSelectDisplayingMode
  from '~/modules/building/components/page/flats/displaying/FlatsSelectDisplayingMode.vue'
import CustomCubeSpinner from '~/ui/spinners/CustomCubeSpinner.vue'
import FlatsSort from '~/modules/building/components/page/flats/sort/FlatsSort.vue'
import CustomButton from '~/ui/buttons/CustomButton.vue'
import FlatsFilter from '~/modules/building/components/page/flats/filter/FlatsFilter.vue'
import ResetButton from '~/modules/market-entities-actions/components/filter/ResetButton.vue'
import { Building } from '~/modules/building/types/Building'
import { useBuildingStore } from '~/modules/building/store'
import type { FlatFilterForRequest } from '~/modules/building/components/page/flats/filter/types/FlatsFilter'
import DISPLAY_TYPE from '~/common/constants/flat/Flat.displayType'
import { FlatStatus } from '~/common/types/flat/Flat.status'
import { FLAT_STATUS } from '~/common/constants/flat/Flat.status'
import { BuildingFlatFilter } from '~/modules/building/components/page/flats/filter/types/BuildingFlatFilter'
import { FlatGuestService } from '~/common/services/flat/FlatGuestService'
import { FlatService } from '~/common/services/flat/FlatService'
import { HouseGuestService } from '~/modules/house/services/HouseGuestService'
import { HouseService } from '~/modules/house/services/HouseService'
import { House } from '~/common/types/house/House'
import { HouseInfo } from '~/modules/house/types/HouseInfo'
import { HouseFlatsInfo } from '~/modules/house/types/HouseFlatsInfo'
import { getDateAndTime } from '~/common/helpers/date/getDateAndTime'

const props = defineProps({
  building: {
    type: Object as PropType<Building>,
    required: true,
  },
  flatDisplayType: {
    type: String as PropType<DISPLAY_TYPE>,
    default: DISPLAY_TYPE.basic,
  },
})

const store = useBuildingStore()

const filter = computed<BuildingFlatFilter>({
  get() {
    return store.filter
  },
  set(value) {
    store.setBuildingFlatFilter(value)
  },
})
const flatService = inject<FlatService | FlatGuestService>('flatService', new FlatService())
const houseService = inject<HouseService | HouseGuestService>('houseService', new HouseService())

const isFetching = ref(false)

const isCountFetching = ref(false)

const searchCount = ref(0)

const housesInfo = ref<Array<HouseInfo>>([])

const houses = computed<Array<House>>(() => props.building.houses)

const houseFlatsInfoById = computed(() => {
  const infoById: Record<number, Array<HouseFlatsInfo>> = {}

  housesInfo.value.forEach(houseInfo => {
    infoById[houseInfo.id] = houseInfo.flatsInfo
  })

  return infoById
})

const flatsSortOption = computed({
  get: () => store.flatsSortOption,
  set: value => {
    if (value !== store.flatsSortOption) {
      store.setFlatsSortOption(value)
      getFlatsInfo()
    }
  },
})

const getFilterForRequest: () => FlatFilterForRequest = () => ({
  flatFilter: filter.value.flatFilter,
  houseFilter: {
    building: { id: props.building?.id },
    deadLine: filter.value.houseFilter.deadLine,
  },
})

const countFlats = () => {
  isCountFetching.value = true

  flatService.count(getFilterForRequest())
    .then(flatsCount => {
      searchCount.value = flatsCount
    })
    .finally(() => {
      isCountFetching.value = false
    })
}

const getFlatsInfo = () => {
  isFetching.value = true
  houseService.searchInfo(getFilterForRequest())
    .then(res => {
      housesInfo.value = res.data
    })
    .finally(() => {
      isFetching.value = false
    })
}

const isNotAvailableShown = computed(() => {
  const statuses = filter.value.flatFilter.status
  return statuses.includes(FLAT_STATUS.booked) && statuses.includes(FLAT_STATUS.sold)
})

const setFlatsStatus = (statuses: Array<FlatStatus>) => {
  store.setFilterValueByKey('flatFilter.status', statuses)
}

const toggleFilterStatus = () => {
  if (isNotAvailableShown.value) {
    setFlatsStatus([FLAT_STATUS.free])
  } else {
    setFlatsStatus([FLAT_STATUS.free, FLAT_STATUS.booked, FLAT_STATUS.sold])
  }
  getFlatsInfo()
}

watch(filter, countFlats, { deep: true })

onMounted(() => {
  getFlatsInfo()
  countFlats()
})

</script>
